<div id="app" class="app">
  <div>
    <router-outlet></router-outlet>
  </div>
</div>

<div id="overlay" style="display: none"></div>

<div id="loadingModal" *ngIf="isShowLoading">
  <div class="container-image">
    <img src="./assets/images/icons/loading-background.svg" />
  </div>
  <div class="modal-backdrop">

  </div>
</div>

<!-- <div
  class="floating__block"
  data-toggle="tooltip"
  data-placement="top"
  title="Hotline: 028 3636 0755"
>
  <a href="tel:+028 3636 0755" class="hint__btn">
    <img src="./assets/images/home/call-center.svg" alt="" />
  </a>
</div> -->

<img src="./assets/images/error/disconnect.png" width="20" height="20" style="display: none" />

<img id="fileUpload" src="./assets/images/error/disconnect.png" width="20" height="20" style="display: none" />