<div id="app" class="app">
  <div>
    <router-outlet></router-outlet>
  </div>
</div>

<div id="overlay" style="display: none"></div>

<div id="loadingModal" *ngIf="isShowLoading">
  <div class="container-image">
    <img src="./assets/images/icons/loading-background.svg" />
  </div>
  <div class="modal-backdrop">

  </div>
</div>

<div class="floating__block" data-toggle="tooltip" data-placement="top" title="Hotline: 028 3636 0755">
  <a href="tel:+028 3636 0755" class="hint__btn">
    <img src="./assets/images/home/call-center.svg" alt="" />
  </a>
</div>

<a id="back-to-top" href="#sections-homepage" class="btn btn-light btn-lg flexbox-center scroll-top-btn"
  title="scroll to top" role="button" [ngClass]="{ 'show': showButton }" (click)="scrollToTop()"><svg
    xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
    <path d="M14 21L14 7M14 7L7 14M14 7L21 14" stroke="#828282" stroke-width="2" stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</a>

<img src="./assets/images/error/disconnect.png" width="20" height="20" style="display: none" />

<img id="fileUpload" src="./assets/images/error/disconnect.png" width="20" height="20" style="display: none" />