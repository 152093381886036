import {BrowserModule} from '@angular/platform-browser';
import { NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import {AppRouting} from "./app.routing";
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {DialogService} from "./common/service/dialog.service";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {FileSaverModule} from "ngx-filesaver";
import {environment} from "../environments/environment";
import {CanDeactivateGuard} from "./common/service/can-deactivate-guard.service";
import {NotFoundComponent} from "./common/components/notFound/notFound.component";
import { MaintenanceComponent } from './common/components/maintenance/maintenance.component';
import { UserRouteAccessDeactivate } from './common/service/user.route.access.service';
import { ViewImageComponent } from './common/components/view-image/view-image';
import { NgbModalModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExecuteBeforeRequestInterceptor } from './common/interceptor/http.interceptor';
import { ErrorHandlerInterceptor } from './common/interceptor/errorhandler.interceptor';
import { AuthInterceptor } from './common/interceptor/auth.interceptor';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgxsModule } from '@ngxs/store';
import { SharedModule } from './common/share.module';
import { AppQueries } from './state';
import { AppState } from './state/app.state';
import { ErrorPopupComponent } from './common/components/error-popup/error-popup';
import { SuccessPopupComponent } from './common/components/success-popup/success-popup';
import { ConfirmPopupComponent } from './common/components/confirm-popup/confirm-popup';
import { WarningPopupComponent } from 'src/app/common/components/warning-popup/warning-popup';
import { NoConnectInternetComponent } from './common/components/no-connect-internet-popup/no-connect-internet-popup';
import { FeatureMaintenanceComponent } from './common/components/feature-maintenance/feature-maintenance.component';
import { PublicConfigModule } from './public-config/public-config.module';

declare let $: any;
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json?cd=" + Date.now());
}
// Create folder load file json language


@NgModule({
  declarations: [
    AppComponent,
    //common
    ErrorPopupComponent,
    SuccessPopupComponent,
    ConfirmPopupComponent,
    WarningPopupComponent,
    FeatureMaintenanceComponent,
    NoConnectInternetComponent,
    NotFoundComponent,
    MaintenanceComponent,
    ViewImageComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    AppRouting,
    FormsModule,
    CommonModule,
    FileSaverModule,
    HttpClientModule,
    PublicConfigModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgbModalModule,
    NgxWebstorageModule.forRoot({ prefix: 'fmarket', separator: '-' }),
    NgxsModule.forRoot([
      AppState,
    ], { developmentMode: !environment.production }),
  ],
  providers: [
    // ToastrService,
    TranslateService,
    AppQueries,
    DialogService,
    CanDeactivateGuard,
    UserRouteAccessDeactivate,
    NgbActiveModal,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ExecuteBeforeRequestInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
